body {
  margin: 0;
  width: 100%;
  height: 100%;
  /*overflow: hidden;*/
}

html {
  overflow: hidden;
  height: 100%;
}
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar for horizontal scrolling */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #333741; /* Color of the scrollbar thumb */
  border-radius: 9999999px; /* Make the scrollbar thumb rounded */
  opacity: 0; /* Make the scrollbar thumb invisible */
}

/* Scrollbar track background */
::-webkit-scrollbar-track {
  background-color: transparent; /* Make the scrollbar track transparent */
  gap: 0px; /* No gap between the track and the thumb */
}
